<template>
<div class="view-home">
        <div class="faq-page">
            <div class="container pl-5">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb pl-0">
                        <li class="breadcrumb-item"><a href="#">{{ $t('breadcrumb.main-page') }}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{{ $t('breadcrumb.faq') }}</li>
                    </ol>
                </nav>
                <div class="page-title  text-lg-left text-center mb-5">
                    {{ $t('faq.title') }}
                </div>
            </div>
            <div class="faq-page pb-5 mb-5">
                <div class="container">
                    <div class="row">
                        <b-card no-body class="col-lg-12 border-0">
                            <b-tabs pills card vertical>
                                <b-tab :title="$t('faq.birge-oqy-title')" active>
                                    <b-card-text>
                                        <div class="faq-accordion">
                                            <div class="card" v-for="(data, key) in accordionBirgeOqy" :key="data.id">
                                                <div class="card-header" @click="accordion = 'collapse' + key" :id="'heading' + key">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link" :class="{'active': accordion == 'collapse' + key}" data-toggle="collapse" :data-target="'#collapse' + key" aria-expanded="false" :aria-controls="'collapse' + key">
                                                    {{ $t(data.title ) }}
                                                    </button>
                                                </h5>
                                                </div>

                                                <div :id="'collapse' + key" :class="{'show': accordion == 'collapse' + key}" class="collapse" :aria-labelledby="'heading' + key" data-parent="#accordion">
                                                <div class="card-body pt-0">
                                                    <hr class="mt-0">
                                                    <div v-html="$t(data.content)"></div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab :title="$t('faq.student-title')">
                                    <b-card-text>
                                        <div class="faq-accordion">
                                            <div class="card" v-for="(data, key) in accordionStudent" :key="data.id">
                                                <div class="card-header" @click="accordion = 'collapse' + key" :id="'heading' + key">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link" :class="{'active': accordion == 'collapse' + key}" data-toggle="collapse" :data-target="'#collapse' + key" aria-expanded="false" :aria-controls="'collapse' + key">
                                                    {{ $t(data.title ) }}
                                                    </button>
                                                </h5>
                                                </div>

                                                <div :id="'collapse' + key" :class="{'show': accordion == 'collapse' + key}" class="collapse" :aria-labelledby="'heading' + key" data-parent="#accordion">
                                                <div class="card-body pt-0">
                                                    <hr class="mt-0">
                                                    <div v-html="$t(data.content)"></div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                                <b-tab :title="$t('faq.parent-title')">
                                    <b-card-text>
                                        <div class="faq-accordion pb-0">
                                            <div class="card" v-for="(data, key) in accordionParent" :key="data.id">
                                                <div class="card-header" @click="accordion = 'collapse' + key" :id="'heading' + key">
                                                <h5 class="mb-0">
                                                    <button class="btn btn-link" :class="{'active': accordion == 'collapse' + key}" data-toggle="collapse" :data-target="'#collapse' + key" aria-expanded="false" :aria-controls="'collapse' + key">
                                                    {{ $t(data.title ) }}
                                                    </button>
                                                </h5>
                                                </div>

                                                <div :id="'collapse' + key" :class="{'show': accordion == 'collapse' + key}" class="collapse" :aria-labelledby="'heading' + key" data-parent="#accordion">
                                                <div class="card-body pt-0">
                                                    <hr class="mt-0">
                                                    <div v-html="$t(data.content)"></div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
       accordion: 'collapse0',
        accordionBirgeOqy: [
            {
            "id": '1',
            'title': 'faq.birge-oqy-q-1',
            'content': 'faq.birge-oqy-a-1',
            },
            {
            "id": '2',
            'title': 'faq.birge-oqy-q-2',
            'content': 'faq.birge-oqy-a-2',
            'content2': ''
            },
            {
            "id": '3',
            'title': 'faq.birge-oqy-q-3',
            'content': 'faq.birge-oqy-a-3',
            },
            {
            "id": '4',
            'title': 'faq.birge-oqy-q-4',
            'content': 'faq.birge-oqy-a-4',
            },
            {
            "id": '5',
            'title': 'faq.birge-oqy-q-5',
            'content': 'faq.birge-oqy-a-5',
            },
            {
            "id": '6',
            'title': 'faq.birge-oqy-q-6',
            'content': 'faq.birge-oqy-a-6',
            },
            {
            "id": '7',
            'title': 'faq.birge-oqy-q-7',
            'content': 'faq.birge-oqy-a-7',
            }
        ],
        accordionStudent: [
            {
            "id": '1',
            'title': 'faq.student-q-1',
            'content': 'faq.student-a-1',
            },
            {
            "id": '2',
            'title': 'faq.student-q-2',
            'content': 'faq.student-a-2',
            'content2': ''
            },
            {
            "id": '3',
            'title': 'faq.student-q-3',
            'content': 'faq.student-a-3',
            },
            {
            "id": '4',
            'title': 'faq.student-q-4',
            'content': 'faq.student-a-4',
            },
            {
            "id": '5',
            'title': 'faq.student-q-5',
            'content': 'faq.student-a-5',
            },
            {
            "id": '6',
            'title': 'faq.student-q-6',
            'content': 'faq.student-a-6',
            }
        ],
        accordionParent: [
            {
            "id": '1',
            'title': 'faq.parent-q-1',
            'content': 'faq.parent-a-1',
            },
            {
            "id": '2',
            'title': 'faq.parent-q-2',
            'content': 'faq.parent-a-2',
            'content2': ''
            },
            {
            "id": '3',
            'title': 'faq.parent-q-3',
            'content': 'faq.parent-a-3',
            },
            {
            "id": '4',
            'title': 'faq.parent-q-4',
            'content': 'faq.parent-a-4',
            },
            {
            "id": '5',
            'title': 'faq.parent-q-5',
            'content': 'faq.parent-a-5',
            }
        ],
    
    }
  },
}
</script>
<style>
.tabs .col-auto {
    height: fit-content;
    margin-bottom: 48px;
}
.nav-link {
    background-color: #fff;
}
.faq-page .nav {
  -webkit-box-shadow: 5px 10px 50px rgba(108, 100, 190, 0.15);
          box-shadow: 5px 10px 50px rgba(108, 100, 190, 0.15);
  border-radius: 10px!important;
  background-color: #fff!important;
  padding: 0;
  width: 350px;
}
.faq-page .nav-item {
  background: #ffffff;
  border-radius: 0px;
  background: transparent;
  font-family: "Roboto Condensed";
  font-size: 20px;
  line-height: 23px;
  color: #303030;
  border-bottom: 0;
  -webkit-transition: -webkit-box-shadow 0.3s linear;
  transition: -webkit-box-shadow 0.3s linear;
  transition: box-shadow 0.3s linear;
  transition: box-shadow 0.3s linear, -webkit-box-shadow 0.3s linear;
  cursor: pointer;
}
.nav-link a {
    color: #303030;
}
b-tab {
    background-color: #fff;
}
.faq-page .nav-link.active {
  -webkit-box-shadow: 5px 10px 50px rgba(108, 100, 190, 0.15);
          box-shadow: 5px 10px 50px rgba(108, 100, 190, 0.15);
  background: transparent;
  color: #303030;
  background: #f8f8f8;
  -webkit-box-shadow: inset 12px 0 #d23168;
          box-shadow: inset 12px 0 #d23168;
}
.faq-page .nav-link:hover {
  -webkit-box-shadow: 5px 10px 50px rgba(108, 100, 190, 0.15);
          box-shadow: 5px 10px 50px rgba(108, 100, 190, 0.15);
  background: transparent;
  color: #303030;
  background: #f8f8f8;
  -webkit-box-shadow: inset 12px 0 #d23168;
          box-shadow: inset 12px 0 #d23168;
}
.faq-page .nav-item:first-child .nav-link {
  border-radius: 0px 10px 0px 0px;
}
.faq-page .nav-item:nth-child(2) .nav-link {
  border-radius: 0px;
}
.faq-page .nav-item:last-child .nav-link  {
  border-radius: 0px 0px 10px 0;
}
.nav-item:last-child .nav-link:focus,
.nav-item:last-child .nav-link:webkit-direct-focus,
.nav-item:last-child .nav-link:focus-visible {
    outline: none!important;
    -webkit-appearance: none!important;
    outline-style: none!important;
    outline-width: 0!important;
}
.faq-page .tab-pane {
    padding-top: 0;
    padding-bottom: 0;
}
.faq-accordion .card button {
    text-align: left;
} 
@media (max-width: 992px) { 
    .faq-page .tabs div:first-child {
        width: 100%;
    }
    .faq-page .tabs div:first-child ul {
        width: 100%;
    }
}
</style>
